/**
 *  CV / Components / Icon
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The icon component
 *
 */

/** Dependencies */
import React from "react";
import { IconType } from "react-icons";

/** Styles */
import * as styles from "./Icon.module.scss";

interface IconProps {
  icon: IconType;
}

export const Icon: React.FC<IconProps> = ({ icon: Icon }) => (
  <Icon size={11} className={styles.root} />
);
