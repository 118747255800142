/**
 *  CV / Components / Divider
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The divider component
 *
 */

/** Dependencies */
import React from "react";
import classNames from "classnames";

/** Styles */
import * as styles from "./Divider.module.scss";

export const Divider: React.FC<React.ComponentPropsWithoutRef<"hr">> = ({
  className,
  ...props
}) => <hr className={classNames(className, styles.root)} {...props} />;
