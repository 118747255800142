/**
 *  CV / Components / Link
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The link component
 *
 */

/** Dependencies */
import React from "react";
import classNames from "classnames";

/** Styles */
import * as styles from "./Link.module.scss";

export const Link: React.FC<React.ComponentPropsWithoutRef<"a">> = ({
  className,
  ...props
}) => <a className={classNames(className, styles.root)} {...props} />;
