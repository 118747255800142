/**
 *  CV / Pages / Index
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The index page of the CV
 *
 */

/** Dependencies */
import * as React from "react";
import { Helmet } from "react-helmet";
import {
  FaLocationArrow,
  FaPhone,
  FaEnvelope,
  FaGlobeEurope,
} from "react-icons/all";

/** Styles */
import "normalize.css";

/** Local Dependencies */
import { Layout } from "../components/Layout";
import { Section } from "../components/Section";
import { Typography } from "../components/Typography";
import { Icon } from "../components/Icon";
import { Link } from "../components/Link";
import { Divider } from "../components/Divider";

/** Images */
import logoUrl from "../images/cv.png";

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Ivan Drinchev - CV</title>
      <link rel="canonical" href="http://cv.drinchev.com" />
    </Helmet>
    <Section title="Personal Information" head>
      <Typography type="name">Ivan Drinchev</Typography>
    </Section>
    <Section title={<img src={logoUrl} alt="Ivan Drinchev" />}>
      <Typography type="content">
        <Icon icon={FaLocationArrow} />
        Berlin, Germany
      </Typography>
      <Typography type="content">
        <Icon icon={FaPhone} />
        <Link href="tel:+49 176 84490877">+49 176 84490877</Link>
      </Typography>
      <Typography type="content">
        <Icon icon={FaEnvelope} />
        <Link href="mailto:drinchev@gmail.com">drinchev@gmail.com</Link>
      </Typography>
      <Typography type="content">
        <Icon icon={FaGlobeEurope} />
        <Link href="https://www.drinchev.com">www.drinchev.com</Link>
      </Typography>
    </Section>
    <Divider />
    <Section title="Work experience" head />
    <Section title="September 2023 - ongoing">
      <Typography type="title">
        Senior Full-stack AI Engineer ( Freelance )
      </Typography>
      <Typography type="content">
        at <Link href="https://www.nexamind.io">Nexamind</Link>
      </Typography>
      <Typography type="content">
        Creating tailor-made AI solutions with measurable ROI, designed for the unique
        needs of the business.
      </Typography>
      <Typography type="title">Tech stack</Typography>
      <Typography type="content">NextJS, Tailwind, React Server Components, Langchain, OpenAI, Langfuse</Typography>
    </Section>
    <Section title="September 2023 - ongoing">
      <Typography type="title">
        Senior Full-stack React/Node Engineer Team Lead ( Freelance )
      </Typography>
      <Typography type="content">
        at <Link href="https://www.yahoo.com">Yahoo!</Link>
      </Typography>
      <Typography type="content">
        Modernizing and maintaining{" "}
        <Link href="https://mail.yahoo.com">Yahoo! Mail</Link>, used by over 200
        million users. My role was to lead a time of 6 developers with the goal to
        re-design Yahoo! Mail. My responsibilities included guiding and mentoring developers,
        actively participating in decision making processes, reviewing Pull Requests and
        writing code.
      </Typography>
      <Typography type="title">Tech stack</Typography>
      <Typography type="content">ReactJS, AWS, NodeJS and others</Typography>
    </Section>
    <Section title="September 2020 - March 2023">
      <Typography type="title">
        Full-Stack Web Developer ( Freelance )
      </Typography>
      <Typography type="content">
        at <Link href="https://www.kieback-peter.com">Kieback & Peter</Link>
      </Typography>
      <Typography type="content">
        Building the IoT Platform{" "}
        <Link href="https://brix2bytes.io">brix2bytes</Link> from scratch in a
        cross-functional teams, including development of applications, UI
        Library, E2E Tests, NodeJS Backend services with GraphQL
      </Typography>
      <Typography type="title">Tech stack</Typography>
      <Typography type="content">
        ReactJS, Azure DevOps, Azure Platform, GraphQL, oAuth, Material UI,
        NodeJS, JEST, Cypress, Terraform, Storybook, Apollo GraphQL
      </Typography>
    </Section>
    <Section title="January 2020 - July 2020">
      <Typography type="title">
        Front-end Web Developer ( Freelance )
      </Typography>
      <Typography type="content">
        at <Link href="https://www.hey.car">Hey Car</Link>
      </Typography>
      <Typography type="content">
        Building and maintaining Hey Car, as well as closed-source UI library
        and internal applications. Participating in Architectural Decision
        Recommendations and front-end best practices workshops.
      </Typography>
      <Typography type="title">Tech stack</Typography>
      <Typography type="content">
        ReactJS, Redux, Mono repo, Server side rendering, GitHub, CircleCI,
        Kubernetes, Styled Components, NodeJS, React Router, Storybook, JEST,
        Cypress
      </Typography>
    </Section>
    <Section title="January 2019 - December 2019">
      <Typography type="title">
        Full-Stack Web Developer & team lead ( Freelance )
      </Typography>
      <Typography type="content">
        at <Link href="https://www.osram.de">OSRAM</Link>
      </Typography>
      <Typography type="content">
        Building and maintaining React SPA, Open Source Stylesheet styleguide,
        React UI Library as well as an open source SDK for OSRAM's IoT platform
      </Typography>
      <Typography type="title">Tech stack</Typography>
      <Typography type="content">
        ReactJS, BitBucket, BitBucket Pipelines, Kubernetes, JIRA, ReactJS, CSS
        Modules, NodeJS, React Router, Styleguidist, JEST, Cypress, OpenAPI,
        PostCSS
      </Typography>
    </Section>
    <Section title="Pre 2019">
      <Typography type="title">Freelance Web Developer</Typography>
      <Typography type="content">
        More than 15+ years of experience in developing web sites and scalable
        web applications ( frontend and backend ), deploying them on various
        hosting providers and delivering maintainable code. Also, I have done
        consultancy on development architectures, talent seeking and tech
        infrastructure.
      </Typography>
      <Typography type="content">
        My paid work also includes ReactJS tutoring
      </Typography>
      <Typography type="title">Notable Clients</Typography>
      <Typography type="content">
        <Link href="https://www.ninox.com">Ninox</Link>,{" "}
        <Link href="https://www.firma.de">firma.de</Link>,{" "}
        <Link href="https://www.twago.de">Twago</Link>,{" "}
        <Link href="https://www.okonni.com">Okonni</Link>,{" "}
        <Link href="https://www.m-sense.de">M-sense</Link>,{" "}
        <Link href="https://www.core.se">Core SE</Link>
      </Typography>
    </Section>
    <Divider />
    <Section title="Community work">
      I've been contributing and maintaining open source projects, such as{" "}
      <Link href="https://github.com/drinchev/monosocialiconsfont">
        Mono Social Icons Font
      </Link>{" "}
      and{" "}
      <Link href="https://github.com/cliefjs/clief">
        Clief - CLI TypeScript framework
      </Link>
      . Check them out on my{" "}
      <Link href="https://github.com/drinchev">GitHub Profile</Link>
    </Section>
  </Layout>
);

export default IndexPage;
